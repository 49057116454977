@import "variables.less";

.form {
  width: 100%;
}

.resetButton {
  width: 100%;
}

.filterButton {
  width: 100%;
  margin-bottom: 10px;
  height: fit-content;
}

.allSourcesArea {
  grid-area: allSourcesArea;
}
.sourcesArea {
  grid-area: sourcesArea;
}
.articlesArea {
  grid-area: articlesArea;
}

.articleSearchArea{
  grid-area: articleSearchArea;
}

.sourceReachArea {
  grid-area: sourceReachArea;
}
.rangeReliabilityArea {
  grid-area: rangeReliabilityArea;
}
.rangeBiasArea {
  grid-area: rangeBiasArea;
}

.moreFilterArea {
  grid-area: moreFilterArea;
}

.viewArea {
  grid-area: viewArea;
}
.resetButtonArea {
  grid-area: resetButtonArea;
}

.formControls {
  display: grid;
  grid-template-columns: 100%;
  grid-template-areas:
    "allSourcesArea"
    "sourcesArea"
    "articlesArea"
    "sourceReachArea"
    "articleSearchArea"
    "rangeReliabilityArea"
    "rangeBiasArea"
    "moreFilterArea"
    "viewArea"
    "resetButtonArea";
}

// @media screen and (max-width: @max-width-app-grid) {
//   .formControls {
//     column-gap: 4%;
//     grid-template-columns: 48% 48%;
//     grid-template-areas:
//       "allSourcesArea articlesArea"
//       "sourcesArea sourceReachArea"
//       "sourcesArea rangeAccuracyArea"
//       "viewArea rangeBiasArea"
//       "resetButtonArea resetButtonArea";
//   }
// }

// @media screen and (max-width: @max-width-app-grid-mobile) {
//   .formControls {
//     grid-template-columns: 100%;
//     grid-template-areas:
//       "allSourcesArea"
//       "sourcesArea"
//       "articlesArea"
//       "sourceReachArea"
//       "rangeAccuracyArea"
//       "rangeBiasArea"
//       "viewArea"
//       "resetButtonArea";
//   }
// }

.verticalField {
  display: block;
}

@font-size-base: 13px;@border-radius-base: 0px;