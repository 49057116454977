@import "~antd/dist/antd.less";
@import url("https://fonts.googleapis.com/css?family=Nunito+Sans");
@import "variables.less";

html {
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
  text-rendering: optimizeLegibility;
  text-size-adjust: 100%;
  font-size: @font-size;
}

body {
  font-family: "Nunito Sans", -apple-system, system-ui, BlinkMacSystemFont,
    Segoe UI, Roboto, Helvetica Neue, Arial, sans-serif;
  font-weight: 400;
  line-height: 1.5;
}

@font-size-base: 13px;@border-radius-base: 0px;