@import "variables.less";
@import "chart.less";

.main {
  display: flex;
  justify-content: center;
  padding: 4px;
  overflow: auto;
  min-width: 1250px;
}

.mainContainer {
  max-width: 2000px;
  width: 100%;
}

.chartTitle {
  display: flex;
  justify-content: center;
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.12), 0 1px 2px rgba(0, 0, 0, 0.24);
  padding: 1em;
  margin: 1em 0em;
  margin-top: 4px;
}

.chartTitle h1 {
  margin: 0em;
  font-size: 2em;
  font-weight: 500;
  letter-spacing: 3px;
  text-transform: uppercase;

  //   @media screen and (max-width: @max-width-app-grid) {
  //     font-size: 1.5em;
  //   }

  //   @media screen and (max-width: @max-width-app-grid-mobile) {
  //     font-size: 1em;
  //   }
}

.chart {
  width: 100%;
  border: 1px solid gainsboro;
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.12), 0 1px 2px rgba(0, 0, 0, 0.24);
  grid-area: chart;

  display: flex;
  flex-direction: column;
  justify-content: center;

  .chartSVG {
    max-height: 1224px;
    max-width: 2082px;
    font-size: 1em;
  }
}

/* controls */
.controlsContainer {
  display: flex;
  flex-direction: column;
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.12), 0 1px 2px rgba(0, 0, 0, 0.24);
  padding: 1.5em;
  width: 100%;
  grid-area: controls;
}

.container {
  display: grid;
  column-gap: 1em;
  grid-template-columns: 240px auto;
  grid-template-areas: "controls chart";
  //   min-width: @minWidth;
}

// @media screen and (max-width: @max-width-app-grid) {
//   .container {
//     grid-template-columns: auto;
//     grid-template-rows: auto auto;
//     row-gap: 1em;
//     grid-template-areas: "chart" "controls";
//   }
// }

.controls {
  padding: 0px 0px;
  border: 1px solid gainsboro;
  width: 100%;
}

.controlsHeader {
  width: 100%;
  font-size: 1.2em;
  text-transform: uppercase;
  letter-spacing: 1px;
  border-bottom: 1px solid gainsboro;
  padding-bottom: 1em;
  text-align: center;
  margin-bottom: 1em;

  @media screen and (max-width: @max-width-app-grid) {
    font-size: 1.1em;
  }

  @media screen and (max-width: @max-width-app-grid-mobile) {
    font-size: 1em;
  }
}

.downloadButton {
  width: 100%;
  margin-top: 10px;
  height: fit-content;
}

.customButton {
  padding: 20px;
}

.hideRibbon{
  display: none;
}
@font-size-base: 13px;@border-radius-base: 0px;